import request from '@/utils/request'

// 是否促销时间
export function extraGift1Time() {
    return request({
        url: '/promotion/extraGift1Time',
        method: 'get'
    })
}

// 土耳其,立陶宛参与12.10会议 资格码 查询
export function listByYearDTO(query) {
    return request({
        url: 'procQualification/listByYearDTO',
        method: 'get',
        params: query
    })
}
// 1210年终兑换码报单
export function YearSaveOrder(data) {
    return request({
        url: 'procQualification/procQualificationYearSaveOrder',
        method: 'post',
        data
    })
}