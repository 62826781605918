<!-- 文件中文名: 经销商注册结算页面 -->
<template>
  <div class="member-register-settle app-container" style="text-align: left">
    <el-form ref="registerRef" :model="registerInfo" label-width="auto" size="mini">
      <!-- 基本信息 -->
      <h3 style="color: #b3640d">{{ $t('Common.basicInfomation') }}</h3>
      <!-- 姓名 -->
      <el-form-item :label="$t('csServiceCenter.trueName')">
        {{ registerInfo.name }}
      </el-form-item>
      <!-- 会员编号 -->
      <el-form-item :label="$t('poOrder.memberNo')">
        {{ registerInfo.memberNo }}
      </el-form-item>
      <!-- 电子邮件 -->
      <el-form-item :label="$t('sys.email')">
        {{ registerInfo.email }}
      </el-form-item>
      <!-- 电话 -->
      <el-form-item :label="$t('alCompany.phone')">
        {{ registerInfo.mobile }}
      </el-form-item>
      <!-- 状态 -->
      <el-form-item :label="$t('sys.status')">
        {{ registerInfo.totalFc }}
      </el-form-item>
      <!-- 安置人编号 -->
      <el-form-item :label="$t('mi.servicerNo_es_ES') + ':'">
        {{ registerInfo.tmpLinkNo }}
      </el-form-item>
      <!-- 推荐人编号 -->
      <el-form-item :label="$t('mi.sellerNo') + ':'">
        {{ registerInfo.recommendNo }}
      </el-form-item>
      <!-- 位置 -->
      <el-form-item :label="$t('sys.Location')">
        <span v-show="registerInfo.lr==0">{{ $t('common.left') }}</span>
        <span v-show="registerInfo.lr==1">{{ $t('common.right') }}</span>
      </el-form-item>
      <div v-show="poOrderVO.recType===3">
        <!-- 发票信息 -->
        <h3 style="color: #b3640d">{{ $t('Invoice.information') }}</h3>
        <!--          发票详细地址-->
        <el-form-item :label="$t('Invoice.detailed.address')+(':')">
          {{ settleData.invoiceAddr }}
        </el-form-item>
        <!--发票地址-->
        <el-form-item :label="$t('Invoice.address')+(':')">
          {{ settleData.invoiceString   || ' ' }}
        </el-form-item>
      </div>
      <!-- 收货信息 -->
      <h3 style="color: #b3640d">{{ $t('register.us.legend.shipping') }}</h3>
      <el-form-item :label="$t('PoOrder.recType')" required>
        <span v-if="poOrderVO.recType===1">
          <!--                代办处提货-->
          {{ this.$t('ru.please.goto.agent.toGet') }}
          {{ poOrderVO.recNo }}
        </span>
        <span v-if="poOrderVO.recType===2">
          <!--                分公司提货-->
          {{ this.$t('pick.up.from.branch.office') }}
          {{ poOrderVO.outCompanycode }}
        </span>
        <span v-if="poOrderVO.recType===3">
          <!--                发到经销商-->
          {{ this.$t('GoodsToMember') }}
        </span>
      </el-form-item>
      <div v-show="poOrderVO.recType===3">
        <!--          详细地址-->
        <el-form-item :label="$t('miAgent.storeAddr')+(':')" required>
          {{ poOrderVO.recAddr }}
        </el-form-item>
        <!--地址-->
        <el-form-item :label="$t('mi.address.id')+(':')" required>
          {{ settleData.regionString || ' ' }}
        </el-form-item>
        <el-form-item :label="$t('busi.JPorder.receiverName')+(':')" required>
          {{ poOrderVO.recName }}
        </el-form-item>
        <el-form-item :label="$t('alCompany.phone')+(':')" required>
          {{ poOrderVO.recPhone }}
        </el-form-item>
      </div>
      <!-- 订单列表 -->
      <h3 style="color: #b3640d">{{ $t('Order.List') }}</h3>
      <el-table :data="this.shoppingList" size="mini" fit>
        <el-table-column :label="$t('sr.productName')" align="center" prop="goodsName">
          <template slot-scope="{ row }">
            {{ $t(row.goodsName) }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('Product.Code')" align="center" prop="goodsCode"/>
        <el-table-column :label="$t('biBarCode.quantity')" align="center" prop="quantity"/>
        <el-table-column :label="$t('PV')" align="right" prop="standardPv"/>
        <el-table-column :label="$t('company.me.totalPV')" align="right">
          <template slot-scope="{ row }">
            <p> {{ row.standardPv * row.quantity }}</p>
          </template>
        </el-table-column>
        <el-table-column :label="$t('pd.price')" align="right">
          <template slot-scope="{ row }">
            <p> {{ row.standardPriceB }} {{ $symbol() }}</p>
          </template>
        </el-table-column>
        <el-table-column :label="$t('TotalPrice')" align="right">
          <template slot-scope="{ row }">
            <p> {{ row.standardPriceB * row.quantity }} {{ $symbol() }}</p>
          </template>
        </el-table-column>
      </el-table>
      <p style="color: #538e0d; font-size: 14px; font-weight: bold">
        <!--        金额-->
        {{ $t('pmEfuboCard.amount') }}
        <span style="color: #f00; font-size: 14px; font-weight: bold">{{ standardPriceSum }}</span> &nbsp;
        <span v-if="settleData.getSendFree" style="color: #333; font-size: 14px;font-weight: 500;">{{
            $t('shipping.cost')
          }}: {{ settleData.getSendFree }}</span>
      </p>
      <el-form-item
          label-width="auto"
          :label="$t('please.input.your.pay.password') + ':'"
          :rules="{required: true,message: this.$t('po.setInfo'),trigger: ['blur', 'change'],}"
          size="mini"
          style="margin-top: 10px"
      >
        <el-input v-model.trim="registerInfo.payPassword" style="width: 55%;" :placeholder="$t('')" clearable
                  show-password/>
      </el-form-item>
    </el-form>
    <div style="text-align: center; margin-bottom: 10px">
      <!--      立即支付-->
      <el-button v-loading="doLoading" icon="el-icon-right" size="mini" type="primary" @click="btnSubmit(0)">
        {{ $t('Pay.Immediately') }}
      </el-button>
      <!--      稍后支付-->
      <el-button v-loading="doLoading" icon="el-icon-right" size="mini" type="warning" @click="btnSubmit(1)">
        {{ $t('btn.pay.later') }}
      </el-button>
      <!--      取消首购单-->
      <el-button v-loading="doLoading" icon="el-icon-right" size="mini" type="danger" @click="cancelFirstPay">
        {{ $t('Cancel.First.Purchase.Order') }}
      </el-button>
    </div>
    <div style="text-align: right;color: red">
      <p style="margin: 0">{{ $t('Payment.exchange_rate.convert') }}</p>
      <p style="margin: 0">{{ $t('ltv.pay.online.message1') }}</p>
    </div>
  </div>
</template>

<script>

import {agentRegisterSecond} from "@/api/register/register";
import {mapGetters} from "vuex";
import {extraGift1Time} from "@/api/promotion/promotion";

export default {
  name: 'MemberRegisterSettle',
  directives: {},
  components: {},
  data() {
    return {
      extraGift1Time: false,
      poOrderVO: {},
      settleData: {},
      registerInfo: {
        payPassword: ''
      },
      shoppingList: [],
      doLoading: false,
    }
  },
  computed: {
    ...mapGetters([
      'companyCode'
    ]),
    standardPriceSum() {
      let standardPriceSum = 0
      this.shoppingList.map(ele => standardPriceSum += ele.standardPriceB * ele.quantity)
      return standardPriceSum
    },
    standardPvSum() {
      let standardPvSum = 0
      this.shoppingList.map(ele => standardPvSum += ele.standardPv * ele.quantity)
      return standardPvSum
    },
  },
  watch: {},
  beforeCreate() {
  },
  async created() {
    let MemberRegisterData = sessionStorage.getItem("MemberRegisterData")
    let OrderSettlement = sessionStorage.getItem("OrderSettlement")
    if (!MemberRegisterData) {
      console.log('MemberRegisterData不存在!')
      await this.$router.push('/member-register/index')
    }
    if (!OrderSettlement) {
      console.log('OrderSettlement不存在!')
      this.$router.go(-1)
    }
    this.shoppingList = JSON.parse(OrderSettlement)
    this.settleData = JSON.parse(MemberRegisterData)
    if (sessionStorage.getItem("tableData")) {
      let a = []
      a = JSON.parse(sessionStorage.getItem("tableData"))
      a.map(ele => {
        this.shoppingList.push(ele)
      })
    }
    if (sessionStorage.getItem("tableData10")) {
      let a = []
      a = JSON.parse(sessionStorage.getItem("tableData10"))
      console.log(a,'aaa')
      a.forEach(v=>{
        this.shoppingList.push(v)
      })
    }
    if(sessionStorage.getItem('PromotionData')){
      let PromotionData = JSON.parse(sessionStorage.getItem('PromotionData'))
      PromotionData.forEach(v=>{
        this.shoppingList.push(v)
      })
    }
    this.registerInfo = this.settleData.miMember
    this.poOrderVO = this.settleData.poOrderVO
    this.registerInfo.totalFc = this.settleData.poOrderVO.totalFc
    this.registerInfo.lr = this.settleData.poOrderVO.lr
    await extraGift1Time().then(res => {
      this.extraGift1Time = res.success
      this.extraGift1Time = false
    })
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    tableRowClassName({row, rowIndex}) {
      if (row.shengji === 1) {
        return 'error-row'
      }
      return ''
    },
    async btnSubmit(val) {
      let strData = []
      let str = ''
      strData[0] = this.$t('mi.servicerNo') + ': '  + this.registerInfo.tmpLinkNo
      strData[1] = this.$t('mi.sellerNo') + ': ' +  this.registerInfo.recommendNo
      strData[2] = this.$t('fi.sure.handle')
      strData.forEach(item => {
        str += `<div>${item}</div>`
      })
      this.$confirm(str, this.$t('common.tips').toString(), {
        confirmButtonText: this.$t('button.confirm').toString(),
        cancelButtonText: this.$t('operation.button.cancel').toString(),
        dangerouslyUseHTMLString:true,
        customClass: 'msgBox',
        type: 'warning'
      }).then(async () => {
        if (!this.registerInfo.payPassword) {
          return this.$message.error(this.$t('please.input.your.pay.password').toString())
        }
        this.settleData.poOrderVO.goodsList = this.shoppingList
        this.settleData.poOrderVO.payPassword = this.registerInfo.payPassword
        this.settleData.poOrderVO.payType = val  // 0为立即支付 1为稍后支付
        this.settleData.poOrderVO.orderType = '17' // 17为首购单
        // 运费赋值
        this.settleData.poOrderVO.sendFree = this.settleData.getSendFree
        // 代办处收货时需要将手机号替换为注册人的手机号
        if (this.poOrderVO.recType === 1) {
          this.settleData.mobile = this.registerInfo.mobile
        }
        this.doLoading = true
        await agentRegisterSecond({...this.settleData})
            .then((res) => {
              if (!val) {
                this.$message.success(this.$t('bdSendRegister.operaterSuccess').toString())
                sessionStorage.removeItem('totalFc')
                sessionStorage.removeItem('MemberRegisterData')
                sessionStorage.removeItem('orderType')
                this.$store.dispatch('shopping/reSetShoppings')
                this.$router.push({path: '/order-list/index'})
              } else {
                if (res.success) {
                  this.$store.dispatch('shopping/reSetShoppings')
                  this.$message.success(this.$t('bdSendRegister.operaterSuccess').toString())
                  sessionStorage.setItem('PayOnlineData', JSON.stringify(res.data.order))
                  sessionStorage.removeItem('totalFc')
                  sessionStorage.removeItem('MemberRegisterData')
                  sessionStorage.removeItem('orderType')
                  this.$router.push({path: '/order-list/index'})
                }
              }
              this.doLoading = false
            })
            .catch(() => {
              this.$message.error(this.$t('bdSendRegister.operaterFail').toString())
              this.doLoading = false
            })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t('operation.button.cancel')
        })
      })
    },
    // TODO:在外部增加确认弹窗
    cancelFirstPay() {
      sessionStorage.removeItem('totalFc')
      sessionStorage.removeItem('MemberRegisterData')
      sessionStorage.setItem("orderType", JSON.stringify(18))
      this.$router.go(0)
    },
  }
}

</script>

<style scoped>
</style>
